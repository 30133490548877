<template>
    <div class="main">
        <el-form label-position="left">
            <el-form-item :label="lang['form.user']">
                <el-input v-model="form.username" />
            </el-form-item>

            <el-form-item :label="lang['form.start']">
                <el-input v-model="form.startInt" />
            </el-form-item>

            <el-form-item :label="lang['form.padding']">
                <el-input v-model="form.padding" />
            </el-form-item>

            <el-form-item :label="lang['form.num']">
                <el-input v-model="form.userNum" type="number"/>
            </el-form-item>

            <el-form-item :label="lang['form.name']">
                <el-input v-model="form.name" />
            </el-form-item>

            <el-form-item :label="lang['form.contact']">
                <el-input v-model="form.contact" />
            </el-form-item>

            <el-form-item>
                <el-tag type="danger">
                    {{lang['form.tips.user']}}: {{makeUserNameExample}}
                </el-tag>

                <el-tag style="margin-top: 5px" type="warning">
                    {{ lang['el-tag.tips.warning'] }}
                </el-tag>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" plain @click="submit" :disabled="isDisabled">{{lang['form.submit']}}</el-button>
                <el-button plain @click="clearForm" :disabled="isDisabled">{{lang['form.reset']}}</el-button>
            </el-form-item>
        </el-form>

        <el-tag>
            {{lang['el-tag.tips']}}
        </el-tag>
    </div>
</template>

<script>
export default {
    name:'userBulk',
    data(){
        return {
            form : {
                username:'',
                startInt:null,//后缀起始值
                userNum:null,//注册数量
                name:'',//姓名
                contact:'',//联系方式
                padding:null,
            },
            isDisabled:false,
            lang:this.$lang.getLang('user','userBulk'),
        }
    },
    computed:{
        //计算生成后的范围
        makeUserNameExample(){
            let form = this.form;
            let bool = form.username && form.startInt && form.userNum;
            let res = ' ~ ';
            if (bool){
                let padding = Number(form.padding);

                let strat = Number(form.startInt);
                strat = this.$helper.fixZeroStart(strat.toString(),padding);

                let end = Number(strat) + Number(form.userNum);
                end = this.$helper.fixZeroStart(end.toString(),padding);

                let startUser = form.username + strat;
                let endUser = form.username + String(end);
                res = startUser + ' ~ ' + endUser;
            }
            return res;
        },
    },
    methods:{
        submit(){
            let form = this.form;
            // 检查请求数据是否正确
            let bool = form.username && form.startInt && form.name && form.contact;
            if(!bool){
                this.$alert(this.lang['submit.error.form']);
                return;
            }
            let int = Number(form.userNum)
            console.log('int',int)
            if (int <=0 || int >2000){
                this.$alert(this.lang['submit.error.num']);
                return;
            }
            this.isDisabled = true;
            this.$message(this.lang['submit.wait']);
            this.$api.post('User.Register/userBulkRegister',form).then(res=>{
                this.isDisabled = false;
                let data = this.$helper.checkRes(res);
                if (data){
                    this.$alert(this.lang['submit.success'],null,{type:'success'});
                    //注册成功,取出注册的账号密码,并弹出下载框
                    this.openDownLoadUserData(data);
                    return;
                }
                let code = this.$helper.getResErrorCode(res);
                let errMsg;
                if (code == 500){
                    //不符合规则
                    errMsg = this.lang['submit.res.code.500'] + '<br>Msg:' + res.data.attch;
                }else{
                    //其他原因
                    let field = 'submit.res.code.' + code;
                    errMsg = this.lang[field] == null ? this.lang['submit.res.code.un'] : this.lang[field];
                }
                this.$alert(errMsg,'Error Code:' + code,{type:'error',dangerouslyUseHTMLString:true})
            }).catch(error=>{
                this.isDisabled = false;
                this.$helper.axiosCatch(error);
            });
        },
        /**
         * 从返回数据取出注册成功的账号密码,并弹出下载框
         * @param data
         */
        openDownLoadUserData(data){
            this.$notify({message:this.lang['openDownLoadUserData.wait'],type:'info'});
            let userData = data.userData;
            if(!userData){
                this.$alert(this.lang['openDownLoadUserData.error.userData']);
                return;
            }
            //生成导出数据
            let enter = '\r\n';
            let header = ['USER','PASSWORD'];
            let userDataStr = '';
            for (let userDataKey in userData) {
                let temp = userData[userDataKey];
                let str = temp.username + ',' + temp.password;
                userDataStr +=str + enter;
            }
            let output = header.toString() + enter + userDataStr;
            this.$helper.downLoad(output,'user.csv');
            setTimeout(()=>{
                this.$notify({message:this.lang['openDownLoadUserData.successful'],type:'success'});
            },1000);
        },
        clearForm(){
            this.form = {username:'', startInt:null, userNum:null, name:'', contact:'',padding:null,};
            // this.form = {username:'xsr', startInt:'1000', userNum:'50', name:'新思睿', contact:'19999999999',};
        },
    }
}
</script>

<style scoped>
.main{
    width: 300px;
    margin:0 auto;
    margin-top: 20px;
}
</style>